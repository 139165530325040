import React from 'react';
import { AwsRumContext } from '../../utils/awsRumContext';
import "./Errors.scss"

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error caught by ErrorBoundary:", error, errorInfo);
        const awsRum = this.context;
        if (awsRum) {
            awsRum.recordEvent('general_exception', {
                errorMessage: error.message,
                errorStack: errorInfo.componentStack
            })

        }
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-page">
                    <div className="error-tile">
                        <div className="error-header">
                            <h1>Oops! Looks like we blew a fuse</h1>
                        </div>
                        <div className="error-visual">
                            <img src="/assets/images/frazzled-light-bulb.webp" alt="Frazzled Light Bulb" />
                        </div>
                        <div className="error-message">
                            <p>Well, this is awkward... It seems like we sent too much current down the wrong path and tripped the circuit. Don't worry - we're onto it; it's been logged and our team are working to fix it!</p>
                        </div>
                        <div className="error-links">
                            <p>While we reset the breaker, why not check out some of these helpful links:</p>
                            <ul>
                                <li><a href="#" onClick={(e) => { e.preventDefault(); window.location.reload(); }}>Try this page again</a></li>
                                <li><a href="/">Home</a></li>
                            </ul>
                        </div>
                        <div className="error-footer">
                            <p>Still in the dark? Reach out to our support team, and we'll light the way.</p>
                            <ul>
                                <li><a href="mailto:support@automised.energy">Contact Us</a></li>
                            </ul>

                            <p className="fun-fact">Fun Fact: Did you know? The average lightning bolt contains enough energy to toast 100,000 slices of bread! ⚡ Too bad we can't harness that power... yet.</p>
                        </div>
                        <img className="logo" alt="Automised logo" src="/assets/images/product-logo-mobile.svg" />
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.contextType = AwsRumContext;


export const handleLogoError = (e) => {
    e.target.onerror = null; // Prevents an infinite loop if the fallback image also fails
    e.target.src = '/assets/images/retailerLogos/alternate.svg';
  };

export default ErrorBoundary;
