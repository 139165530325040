import { useContext, useEffect, useState } from 'react';
import './CompletedConsent.scss'
import OnboardingBaseView from './OnboardingBaseView';
import { OnboardingContext } from '../../utils/OnboardingContext';

export const CompletedConsent = () => {
    const { flowStep, gotoDashboard, gotoNextStep, gotoPrevStep, selectedProvider, progressBarSteps, currentProgressBarStep } = useContext(OnboardingContext);

    useEffect(() => {

    }, []);

    return (
        <div className='flowStepContent'>
            <img src={`/assets/images/tickDone.png`} className='valuePropImage' alt='Tick image' />
            <div className='heading'>
                <h1 className='headline'>Done!</h1>
                <span className='byline'>Your data has been collected from <strong>Retailer Brand Name</strong>. We will now process your data to find you savings.</span>
            </div>
            <div className='redirectImage'>
            </div>
            <div className='nav'>
                <button onClick={gotoDashboard} className='primary next'>Discover savings!</button>
            </div>
        </div>
    )
};