import { useContext, useEffect, useState } from 'react';
import './Confirm.scss'
import OnboardingBaseView from './OnboardingBaseView';
import { OnboardingContext } from '../../utils/OnboardingContext';

export const Collecting = () => {
    const { flowStep, gotoDashboard, gotoNextStep, gotoPrevStep, selectedProvider, progressBarSteps, currentProgressBarStep } = useContext(OnboardingContext);

    useEffect(() => {

    }, []);

    return (
        <div className='flowStepContent'>
            <img src={`/assets/images/plane.png`} className='valuePropImage' alt='Sending data image' />
            <div className='heading'>
                <h1 className='headline'>Almost there</h1>
                <span className='byline'>Your data is on its way from <strong>{selectedProvider}</strong>. Not long now to discover how much you could save!</span>
            </div>
            <div className='in-progress'></div>
        </div>
    )
};