import { useContext, useEffect, useState } from 'react';
import './SystemError.scss'
import OnboardingBaseView from './OnboardingBaseView';
import { OnboardingContext } from '../../utils/OnboardingContext';

export const SystemError = () => {
    const { flowStep, gotoDashboard, gotoNextStep, gotoPrevStep, selectedProvider, progressBarSteps, currentProgressBarStep } = useContext(OnboardingContext);

    useEffect(() => {

    }, []);

    // Function to refresh the current page
    const refreshPage = () => {
        window.location.reload();
    }

    return (
        <div className='flowStepContent'>
            <img src={`/assets/images/frazzled-light-bulb.webp`} className='valuePropImage' alt='Error image' />
            <div className='heading'>
                <h1 className='headline'>There's been a problem</h1>
                <span className='byline'>We've encountered an issue while trying to collect your data from {selectedProvider}. This issue has been logged and will be investigated by our team. If the problem persists, try again later or contact <a href="mailto:support@automised.energy">support@automised.energy</a>.</span>
            </div>
            <div className='redirectImage'>
            </div>
            <div className='nav'>
                <button onClick={refreshPage} className='primary next'>Try again now</button>
            </div>
        </div>
    )
};