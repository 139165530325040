import React, { useState, useEffect, useContext } from 'react';


export const SessionContext = React.createContext();

export const SessionProvider = ({ children }) => {
    const [sessionContext, setSessionContext] = useState({});
  
    return (
      <SessionContext.Provider value={{ sessionContext, setSessionContext }}>
        {children}
      </SessionContext.Provider>
    );
  };