import React, { createContext, useState, useEffect, useContext } from 'react';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import axios from 'axios';
import { SessionContext } from './SessionContext';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { sessionContext, setSessionContext } = useContext(SessionContext);

  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [userProfileCheckComplete, setUserProfileCheckComplete] = useState(null);

  useEffect(() => {
    const isAuthenticated = fetchAuthSession()
      .then((userSession) => {
        //console.debug(`From AuthContext. User session: ${JSON.stringify(userSession)}`)
        //let userSession = fetchAuthSession().then((session) => { console.debug(`fetchAuthSession: ${JSON.stringify(session)}`) });
        let userAttributes = userSession.tokens.idToken.payload;
        let user = {
          username: userAttributes["cognito:username"],
          userId: userSession.userSub,
          attributes: userAttributes
        }
        //console.debug(`From authContext: isAuthenticated: ${JSON.stringify(userSession)}`)
        setAuthenticatedUser(user);
        return true;
      })
      .catch((argument) => {
        console.debug(`From authContext: error encountered while looking up current authenticated user: ${JSON.stringify(argument)}`)
        setAuthenticatedUser(undefined);
        return false;
      });
  }, []);

  useEffect(() => {
    if (!authenticatedUser) {
      setUserProfile(null);
      return;
    }

    //Get the user profile details
    axios.get(`${process.env.REACT_APP_API_URL}consumers/profile`)
      .then(response => {
        setUserProfile(response.data);
      })
      .catch(error => {
        if (error.response) {
          const status = error.response.status;

          //TODO: If 404 not found, then launch new user flow (ie set user profile to empty). If other error, then show error message (ie set user profile to undefined)
          if (status === 404) {
            setUserProfile({}); //Indicating a new user
          } else if (status === 401) {
            setUserProfile(undefined); //Indicating we weren't able to tell if there is a user profile for this authenticated consumer (e.g. user could not be authenticated)
            setSessionContext({ ...sessionContext, notificationMessage: `Unauthorised access. Please log in again.` });
          } else if (status === 500) {
            setUserProfile(undefined); //Indicating we weren't able to tell if there is a user profile for this authenticated consumer (e.g. system error)
            setSessionContext({ ...sessionContext, notificationMessage: `System error. Please try again later.` });
          } else {
            // Handle other errors
            setUserProfile(undefined);
            setSessionContext({ ...sessionContext, notificationMessage: `An error occurred. Please try again.` });
          }
          console.error('Error fetching user profile:', error);
        } else {
          console.error(`Could not get profile information for user due to connection error, ${error}`)
        }
      })
      .finally(() => {
        setUserProfileCheckComplete(true)
      });


  }, [authenticatedUser]);

  return (
    <AuthContext.Provider value={{ authenticatedUser, setAuthenticatedUser, userProfile, userProfileCheckComplete }}>
      {children}
    </AuthContext.Provider>
  );
};
