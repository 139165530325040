import { useContext, useEffect, useState, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../utils/AuthContext';
import './NewUser.scss'
import { ValueProp1, ValueProp2, ValueProp3 } from '../../components/Onboarding/ValueProps';
import { OnboardingContext } from '../../utils/OnboardingContext';
import { GetStartedWithBiza } from '../../components/Onboarding/GetStartedWithBiza';
//import { GetStarted } from '../../components/Onboarding/GetStarted';
//import { ChooseProvider } from '../../components/Onboarding/ChooseProvider';
//import { Consent } from '../../components/Onboarding/Consent';
//import { Connecting } from '../../components/Onboarding/Connecting';
//import { Confirm } from '../../components/Onboarding/Confirm';
import { IncompleteConsent } from '../../components/Onboarding/IncompleteConsent';
import OnboardingBaseView from '../../components/Onboarding/OnboardingBaseView';
import * as AutomisedAPIs from '../../utils/automisedAPIs';
import { CompletedConsent } from '../../components/Onboarding/CompletedConsent';
import { Collecting } from '../../components/Onboarding/Collecting';
import { SystemError } from '../../components/Onboarding/SystemError';
import { BetaFilter } from '../../components/Onboarding/BetaFilter';

const NewUser = () => {
    const { authenticatedUser, userProfile } = useContext(AuthContext);
    const sortAgreementsByDate = (agreementsArray) => {
        const sortedArray = Array.isArray(agreementsArray) && agreementsArray.length > 0
            ? agreementsArray.sort((a, b) => {
                const dateA = new Date(a.details.primed.date);
                const dateB = new Date(b.details.primed.date);
                return dateB - dateA; // For descending order
            })
            : [];

        return sortedArray;
    }
    const activeCdrAgreements = userProfile.cdrAgreements ? sortAgreementsByDate(Object.values(userProfile.cdrAgreements).filter(agreement => agreement.status === 'ACTIVE')) : false;
    const primedCdrAgreements = userProfile.cdrAgreements ? sortAgreementsByDate(Object.values(userProfile.cdrAgreements).filter(agreement => agreement.status === 'PRIMED')) : false;
    const flowStepsAr =
        [
            ['valueProp1', { component: <ValueProp1 />, progressBarStep: undefined }],
            ['valueProp2', { component: <ValueProp2 />, progressBarStep: undefined }],
            ['valueProp3', { component: <ValueProp3 />, progressBarStep: undefined }],
            ['betaFilter', { component: <BetaFilter />, progressBarStep: undefined }],
            ['getStartedWithBiza', { component: <GetStartedWithBiza />, progressBarStep: undefined }],
            //['getStarted', { component: <GetStarted />, progressBarStep: undefined }],
            //['chooseProvider', { component: <ChooseProvider />, progressBarStep: 'Provider' }],
            //['consent', { component: <Consent />, progressBarStep: 'Consent' }],
            //['connecting', { component: <Connecting />, progressBarStep: 'Connect' }],
            //['confirm', { component: <Confirm />, progressBarStep: 'Confirm' }],
            ['loading', { component: <>Loading...</>, progressBarStep: undefined, backLink: false }],
            ['error-scopes', { component: <>!Error! - Not all required scopes were consented to</>, progressBarStep: undefined, backLink: false }],
            ['error-finalising', { component: <SystemError />, progressBarStep: undefined, backLink: false }],
            ['error-incomplete-consent', { component: <GetStartedWithBiza status="incompleteConsent" />, progressBarStep: undefined, backLink: false }],
            ['finalising', { component: <Collecting />, progressBarStep: undefined, backLink: false }],
            ['success', { component: <CompletedConsent />, progressBarStep: undefined, backLink: false }],
        ]
    const flowStepsMap = new Map(flowStepsAr);

    const progressBarSteps = flowStepsAr
        .map(step => step[1].progressBarStep) // Extract progressBarStep from each value
        .filter(progressBarStep => progressBarStep !== undefined); // Filter out undefined values

    const flowStepIdsAr = flowStepsAr
        .map(step => step[0]);

    const [flowStep, setFlowStep] = useState('loading');
    const [selectedProvider, setSelectedProvider] = useState(undefined);
    const [inProp, setInProp] = useState(false);
    const navigate = useNavigate();
    const [agreementDetails, setAgreementDetails] = useState(undefined);

    useEffect(() => {
        async function checkUserState(state) {
            console.debug(`From NewUser: authenticatedUser in context: ${JSON.stringify(authenticatedUser)}`)
            console.debug(userProfile);
            console.debug(`State id: ${state}`);

            if (userProfile.cdrAgreements) { //Logic for when a CDR agreement exists
                if (userProfile.status == "PROSPECT") {
                    if (primedCdrAgreements.length > 0) {
                        const targetPrimedCdrAgreement = state ? primedCdrAgreements.find(agreement => agreement.details.requested.data.state === state)?.details?.primed : primedCdrAgreements[0]?.details?.primed;  //Obviously matching on 'state' is ideal, but just in case that is missing we'll take a stab at the first primed agreement
                        setAgreementDetails(targetPrimedCdrAgreement.data);
                        //if (!targetPrimedCdrAgreement) throw new Error(`Bad state value. Could not find correlating agreement.`);
                        console.debug(`User is half-way through onboarding. An agreement has already been primed. Need to check if it has been completed/confirmed or not: ${JSON.stringify(targetPrimedCdrAgreement)}`);
                        const primedAgreementStatus = await AutomisedAPIs.getCdrAgreementStatus(targetPrimedCdrAgreement.data.agreementId);
                        if (primedAgreementStatus.status === "ACTIVE") { //Consent flow was completed and agreement is active. Data can be collected
                            setSelectedProvider(primedAgreementStatus.providerName); //Until now, we don't know which provider the user has chosen. Now we do, so let's set this context variable
                            //Did the user consent to all scopes that are required?
                            if (primedAgreementStatus.scopeCongruence) { //Yes - all scopes selected. Data is good to be collected
                                console.debug(`Consent finalised and ACTIVE. Ready to collect and use data.`)
                                //Kick-off the process of collecting the data, so that it is ready for consumer
                                setFlowStep('finalising');
                                console.debug(`Finalising the onboarding for this user`);
                                AutomisedAPIs.finaliseOnboarding(targetPrimedCdrAgreement.data.agreementId)
                                    .then(() => {
                                        console.debug(`Onboarding succesfully finalised!`)
                                        //Show success step
                                        setFlowStep('success');
                                    })
                                    .catch(error => {
                                        console.error(`Could not finalise onboarding: ${error}`)
                                        //Show error step
                                        setFlowStep('error-finalising')
                                    })
                            } else { //No, there is amismatch between scopes required and scopes consented
                                console.debug(`Incongruent scope consent. Need to show an error page...`)
                                setFlowStep('error-scopes')
                            }
                        } else { //Consent flow was not completed...
                            console.debug(`Consent flow not complete. Need to try again.`)
                            setFlowStep('error-incomplete-consent')
                        }
                    } else { //Profile status of PROSPECT, but no active CDR agreements. Can happen if agreement has been revoked.
                        setFlowStep('getStartedWithBiza');
                    }
                } else if (userProfile.status == "ACTIVE") {
                    if (activeCdrAgreements.length > 0) { //Active consumer + an active agreement; they're good to go. Redirect to dashboard
                        navigate('/');
                    }
                }
            } else {
                setFlowStep(flowStepsAr[0][0])
            }
        }
        const queryParams = new URLSearchParams(window.location.search);
        const consentFlowStateParam = queryParams.get('state');

        checkUserState(consentFlowStateParam);
    }, []);

    const gotoDashboard = () => {
        //navigate('/');
        window.location.href = '/';
    }

    const gotoNextStep = () => {
        let indexOfCurrentFlowStep = flowStepIdsAr.indexOf(flowStep);
        if ((indexOfCurrentFlowStep + 1) == flowStepIdsAr.length) { //At end of flowSteps. At last step. No more steps to go to.
            return;
        }
        //Update context to be the next step
        setInProp(false);
        setFlowStep(flowStepIdsAr[indexOfCurrentFlowStep + 1]);
    }

    const gotoPrevStep = () => {
        let indexOfCurrentFlowStep = flowStepIdsAr.indexOf(flowStep);
        if (indexOfCurrentFlowStep == 0) { //At start of flowSteps. At first step. No more steps to go to.
            return;
        }
        //Update context to be the next step
        setInProp(false);
        setFlowStep(flowStepIdsAr[indexOfCurrentFlowStep - 1]);
    }

    const gotoGetStarted = () => {
        setInProp(false);
        setFlowStep('getStarted');
    }

    useEffect(() => {
        //setInProp(true);

    }, [flowStep]);

    return (
        <OnboardingContext.Provider value={{ flowStepIdsAr, flowStep, currentFlowStepConf: flowStepsMap.get(flowStep), gotoDashboard, gotoNextStep, gotoPrevStep, gotoGetStarted, progressBarSteps, currentProgressBarStep: flowStepsMap.get(flowStep).progressBarStep, selectedProvider, setSelectedProvider, inProp, setInProp, primedCdrAgreements, agreementDetails }}>
            <OnboardingBaseView>
                {flowStepsMap.get(flowStep).component}
            </OnboardingBaseView>
        </OnboardingContext.Provider>
    )

};


export default NewUser;
